import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { DashCamposPersonalizados } from '@/shared/dtos/DashboardsDtos/DashCamposPersonalizados';

@Module({
    namespaced: true,
    name: 'dash_campos_personalizadoModule',
    store,
    dynamic: true
})
class dash_campos_personalizadoModule extends VuexModule {

    public dashboad: DashCamposPersonalizados[] = [];

    @Action({ commit: 'onGetcampos_personalizados' })
    public async getDashMedicionesCampos() {
        return await ssmHttpService.get(API.campos_personalizados + '/dash_mediciones_app/');
    }

    @Mutation
    public onGetcampos_personalizados(res: DashCamposPersonalizados[]) {
        this.dashboad = res ;//? res.map((x) => new DashCamposPersonalizados(x)) : []
    }


}
export default getModule(dash_campos_personalizadoModule);