




















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import DashEvolucionPacienteModule from "@/store/modules/DashEvolucionPaciente-module";
import dash_campos_personalizadoModule from "@/store/modules/Campos_personalizados/dash_campos_personalizado-module";

@Component({
  components: {
    LineMouths: () => import("@/components/Charts/LineMouths.vue"),
    ChartLine: () => import("@/components/Charts/ChartLine.vue"),
  },
})
export default class SeguimientoMenu extends Vue {
  public created() {
    DashEvolucionPacienteModule.getDashPaciEvolucionPacientes();
    dash_campos_personalizadoModule.getDashMedicionesCampos();
  }
  public get source() {
    return DashEvolucionPacienteModule.DashEvolucion;
  }
  public get source_campos() {
    return dash_campos_personalizadoModule.dashboad;
  }
  public get tiene_datos() {
    var tiene: boolean = true;
    try {
      if (this.source.peso.length > 1) {
        tiene = false;
      }
      if (this.source.porcen_grasa.length > 1) {
        tiene = false;
      }
      if (this.source.cintura.length > 1) {
        tiene = false;
      }
      if (this.source.cadera.length > 1) {
        tiene = false;
      }
      if (this.source.muneca.length > 1) {
        tiene = false;
      }
      if (this.source.altura.length > 1) {
        tiene = false;
      }
    } catch (error) {}
    return tiene;
  }
  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 150;
    return "height:" + h + "px;overflow: auto;";
  }
  public Add() {
    this.$router.push({ name: RouterNames.seguimiento_add });
  }
}
