import { BaseDto } from '@/shared/dtos/base-dto';
import { DashTipoDatoFecha } from './DashTipoDatoFecha';
export class EvolucionMediciones extends BaseDto {
    public peso : DashTipoDatoFecha[]=[];
    public porcen_grasa !: DashTipoDatoFecha[];
    public cintura !: DashTipoDatoFecha[];
    public cadera !: DashTipoDatoFecha[];
    public muneca !: DashTipoDatoFecha[];
    public altura !: DashTipoDatoFecha[];
} 
