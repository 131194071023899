import { BaseDto } from '@/shared/dtos/base-dto';
import { ApexCharsSeries } from './ApexCharsSeries';
export class Comparacion_mediciones extends BaseDto {
    public peso : ApexCharsSeries[]=[];
    public porcen_grasa !: ApexCharsSeries[];
    public cintura !: ApexCharsSeries[];
    public cadera !: ApexCharsSeries[];
    public muneca !: ApexCharsSeries[];
    public altura !: ApexCharsSeries[];
} 
